// langContext.js
import React, { useState } from "react";
import MensajesIngles from './../lang/en-US.json';
import MensajesEspañol from './../lang/es-MX.json';
import { IntlProvider } from 'react-intl';

const LangContext = React.createContext();

const LangProvider = ({ children }) => {
    // Cambiamos los valores predeterminados a inglés
    let localPorDefecto = 'en-US';
    let mensajesPorDefecto = MensajesIngles;
    const lang = localStorage.getItem('lang');

    // Si hay un idioma guardado en localStorage, lo usamos
    if (lang) {
        localPorDefecto = lang;
        switch (lang) {
            case 'es-MX':
                mensajesPorDefecto = MensajesEspañol;
                break;
            case 'en-US':
                mensajesPorDefecto = MensajesIngles;
                break;
            default:
                mensajesPorDefecto = MensajesIngles;
                localPorDefecto = 'en-US';
        }
    }

    const [mensajes, establecerMensajes] = useState(mensajesPorDefecto);
    const [locale, establecerLocale] = useState(localPorDefecto);

    const establecerLenguaje = (lenguaje) => {
        switch (lenguaje) {
            case 'en-US':
                establecerMensajes(MensajesIngles);
                establecerLocale('en-US');
                localStorage.setItem('lang', 'en-US');
                break;
            case 'es-MX':
                establecerMensajes(MensajesEspañol);
                establecerLocale('es-MX');
                localStorage.setItem('lang', 'es-MX');
                break;
            default:
                establecerMensajes(MensajesIngles);
                establecerLocale('en-US');
                localStorage.setItem('lang', 'en-US');
        }
    };

    return (
        <LangContext.Provider value={{ establecerLenguaje }}>
            <IntlProvider locale={locale} messages={mensajes}>
                {children}
            </IntlProvider>
        </LangContext.Provider>
    );
};

export { LangProvider, LangContext };
