import React, { useContext } from "react";
import { LangContext } from "../context/langContext"; // Ajusta la ruta según tu estructura
import { FormattedMessage } from 'react-intl';
import En from "../images/en.png"; // Imagen para el idioma inglés
import Es from "../images/es.png"; // Imagen para el idioma español
import '../LanguageSwitcher.css';

const LanguageSwitcher = () => {
    const { establecerLenguaje } = useContext(LangContext);

    return (
        <div className="language-switcher">
            <p className="switcher-text"><FormattedMessage id="swap.idioma" defaultMessage="Change language:" /></p>
            <div className="language-buttons">
                <button
                    onClick={() => establecerLenguaje("en-US")}
                    className="button-language"
                >
                    <img src={En} alt="English" className="language-icon" />
                </button>
                <button
                    onClick={() => establecerLenguaje("es-MX")}
                    className="button-language"
                >
                    <img src={Es} alt="Español" className="language-icon" />
                </button>
            </div>
        </div>
    );
};

export default LanguageSwitcher;
