import React from "react";
import { Pie } from "react-chartjs-2";
import { FormattedMessage, useIntl } from 'react-intl';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from "chart.js";

// Registramos los elementos de Chart.js
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

const WalletDistributionChart = () => {
    const intl = useIntl();

    // Datos del gráfico
    const data = {
        labels: [
            intl.formatMessage({ id: "chart.putas" }),
            intl.formatMessage({ id: "chart.mama" })
        ],
        datasets: [
            {
                label: intl.formatMessage({ id: "chart.info" }),
                data: [80, 20],  // Datos para cada sección (80% para una, 20% para otra)
                backgroundColor: ["#FF6347", "#32CD32"],  // Colores más vibrantes
                borderColor: ["#FF6347", "#32CD32"],      // Colores de borde
                borderWidth: 2,
            },
        ],
    };

    // Opciones para el gráfico
    const options = {
        responsive: true,
        animation: {
            duration: 1500,
            easing: "easeOutBounce",  // Efecto de rebote en la animación
        },
        plugins: {
            legend: {
                position: "top",  // Posición de la leyenda
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        return `${tooltipItem.label}: ${tooltipItem.raw}%`;  // Muestra el porcentaje en el tooltip
                    },
                },
            },
        },
    };

    // Estilos dinámicos con media queries
    const containerStyle = {
        width: "100%",
        maxWidth: "55%", // Default for larger screens
        margin: "0 auto",
        padding: "2em",
        textAlign: "center",
        backgroundColor: "transparent",
        borderRadius: "0.5em",
        overflowWrap: "break-word",
        "@media (max-width: 1080px)": {
            maxWidth: "90%", // Ajusta a 80% en pantallas medianas
        },
        "@media (max-width: 768px)": {
            maxWidth: "90%", // Igual para pantallas más pequeñas
        },
        "@media (max-width: 480px)": {
            maxWidth: "90%", // Igual para pantallas móviles
        },
    };

    return (
        <div style={containerStyle}>
            <h2><FormattedMessage id="chart.owner" defaultMessage="Wallet Owner Distribution" /></h2>
            <Pie data={data} options={options} />
        </div>
    );
};

export default WalletDistributionChart;
