import React from "react";
import { WalletProviderComponent } from "./components/WalletContext"; // Proveedor de Wallet
import Navbar from "./components/Navbar"; // Componente Navbar
import TransferButton from "./components/TransferButton"; // Componente TransferButton
import TokenDisplay from "./components/TokenDisplay"; // Componente TokenDisplay
import WalletDistributionChart from "./components/tokenomics"; // Componente WalletDistributionChart
import Footer from "./components/Footer"; // Componente Footer
import LanguageSwitcher from "./components/LanguageSwitcher"; // Importamos el LanguageSwitcher
import CenteredImage from "./components/CenteredImage";
import Guy from "./images/feed.png"; 
import Phone from "./images/binance.png";
import Disclaimer from "./components/Disclaimer";
import About from "./components/About";
import "./index.css"; // Estilos
import "./App.css"; // Estilos

const App = () => {
    return (
        <WalletProviderComponent>
            <div className="App">
                <Navbar /> {/* Navbar */}
                <LanguageSwitcher /> {/* Componente de cambio de idioma */}
                <div style={{ marginTop: "1em", textAlign: "center" }}>
                    <TokenDisplay />
                </div>
                <div style={{ marginTop: "1em", textAlign: "center" }}> 
                    <div className="vibrate"> 
                <CenteredImage src={Phone} alt="Binance" />
                </div>
                </div>
                <div style={{ marginTop: "2em", textAlign: "center" }}>
                    <TransferButton /> {/* Botón de transferencia */}
                </div>
                <Disclaimer />
                <div style={{ marginTop: "0.5em", textAlign: "center" }}>
                    <WalletDistributionChart /> {/* Gráfico */}
                </div>
                <div style={{ marginTop: "1em", textAlign: "center" }}>
                <CenteredImage src={Guy} alt="Bastard" />
                </div>
                <div style={{ marginTop: "1em", textAlign: "center" }}>
                <About/>
                </div>
                <Footer /> {/* Footer */}
            </div>
        </WalletProviderComponent>
    );
};

export default App;
