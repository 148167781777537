import React from "react";
import Swal from "sweetalert2";
import LOGO from '../images/logo.png';
import { FormattedMessage, useIntl } from 'react-intl';

const TokenDisplay = () => {
    const intl = useIntl();
    const tokenAddress = "3a51RYnMNeM8HxcffdxBy5bSN4JL7TZPZuVccSbZpump";

    const copyToClipboard = () => {
        navigator.clipboard.writeText(tokenAddress).then(() => {
            Swal.fire({
                icon: "success",
                title: intl.formatMessage({ id: 'alert.copied' }),
                text: intl.formatMessage({ id: 'alert.copiade' }),
                confirmButtonColor: "#3498db",
            });
        });
    };

    return (
        <div style={styles.container}>
            <div style={styles.logo}><img src={LOGO} alt="LOGO" style={styles.logoImage} /></div>
            <p style={styles.token}>{tokenAddress}</p>
            <button style={styles.button} onClick={copyToClipboard}>
                <FormattedMessage id="token.copy" defaultMessage="Copy Token" />
            </button>
        </div>
    );
};

// Estilos en línea para el componente
const styles = {
    container: {
        margin: "1em",
        padding: "1em",
        textAlign: "center",
        backgroundColor: "transparent",
        borderRadius: "0.5em",
    },
    logo: {
        fontSize: "3em",
        marginBottom: "0.5em",
    },
    logoImage: {
        width: "100%",       // Asegura que la imagen se ajuste al ancho disponible
        maxWidth: "200px",   // Limita el tamaño máximo para evitar que se haga demasiado grande
        height: "auto",      // Mantiene la relación de aspecto
    },
    token: {
        fontSize: "1.2em",
        margin: "0.5em 0",
        color: "black",
        wordBreak: "break-word",
    },
    button: {
        fontSize: "1em",
        padding: "0.5em 1em",
        backgroundColor: "#3498db",
        color: "white",
        border: "none",
        borderRadius: "0.5em",
        cursor: "pointer",
        transition: "all 0.3s ease",
    },
    buttonHover: {
        backgroundColor: "#2980b9",
    },
    buttonActive: {
        transform: "scale(0.95)",
    },
};

export default TokenDisplay;
