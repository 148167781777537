import React from 'react';
import ReactDOM from 'react-dom/client'; // Importa createRoot desde react-dom/client
import { Buffer } from 'buffer'; // Importa Buffer para configurarlo globalmente
import App from './App';
import { WalletProviderComponent } from './components/WalletContext';
import { LangProvider } from './context/langContext';

// Configura Buffer globalmente
window.Buffer = Buffer;

// Selecciona el elemento root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Renderiza la aplicación utilizando root.render
root.render(
    <React.StrictMode>
        <WalletProviderComponent>
        <LangProvider>
            <App />
        </LangProvider>
        </WalletProviderComponent>
    </React.StrictMode>
);
