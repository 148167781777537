// Footer.js
import React from 'react';
import '../Footer.css';


const Footer = () => {
  return (
    <footer className="footer-container tu-clase">
      <div className="footer-socials">
        <a href="https://x.com/PenisMemeSolana" className="footer-link" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://t.me/penismemesolana" className="footer-link" target="_blank" rel="noopener noreferrer">Telegram</a>
        <a href="https://chat.whatsapp.com/D399r42IeRzGO3HFQOQ0sh" className="footer-link" target="_blank" rel="noopener noreferrer">Whatsapp</a>
      </div>
      <div className="footer-rights">
        &copy; {new Date().getFullYear()} PENIS COIN. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
