import React from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const Navbar = () => {
    return (
        <nav style={styles.navbar}>
            <div style={styles.navContent}>
                <h1 style={styles.logo}>💩</h1>
                <WalletMultiButton style={styles.walletButton} />
            </div>
        </nav>
    );
};

// Estilos en línea para el Navbar
const styles = {
    navbar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1em",
        margin: "1em",
        backgroundColor: "#2c3e50",
        color: "#ecf0f1",
        borderRadius: "0.5em",
        boxShadow: "0 0.5em 1em rgba(0, 0, 0, 0.2)",
    },
    navContent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        maxWidth: "60em",
    },
    logo: {
        fontSize: "1.8em",
        fontWeight: "bold",
        margin: "0",
    },
    walletButton: {
        fontSize: "1em",
        padding: "0.5em 1em",
        backgroundColor: "#3498db",
        color: "#fff",
        border: "none",
        borderRadius: "0.5em",
        cursor: "pointer",
    },
};

export default Navbar;
