import React, { useMemo } from "react";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import "@solana/wallet-adapter-react-ui/styles.css";

// Proveedor del contexto
export const WalletProviderComponent = ({ children }) => {
    const endpoint = useMemo(() => "https://solana-mainnet.g.alchemy.com/v2/GXdV9rLoEv0hZ6YwqjjVlWcmyTO80iNK", []); // Usamos el endpoint de Alchemy
    const wallets = useMemo(() => [], []); // Lista vacía de wallets, puedes agregar wallets aquí si es necesario.

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default WalletProviderComponent;
