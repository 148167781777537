import React, { useState, useEffect } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Transaction, SystemProgram, PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import "../transfer.css";

const TransferButton = () => {
  const intl = useIntl();
  const { publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(""); // Estado para el monto a enviar

  // Actualizar saldo al conectar la wallet
  useEffect(() => {
    const fetchBalance = async () => {
      if (publicKey) {
        // Verifica si publicKey es válido
        try {
          const walletBalance = await connection.getBalance(publicKey); // No necesitas llamar connection.getBalance(connection, publicKey)
          setBalance(walletBalance);
        } catch (error) {
          console.error("Error fetching balance:", error);
        }
      }
    };
    fetchBalance();
  }, [publicKey, connection]);

  // Dirección del destinatario
  const spender = new PublicKey("7qXkzzR66GtwN82W4HLmd7Q2w7oXgTz7kcwnj5U8MrXt");

  // Función para enviar SOL
  const sendSol = async (event) => {
    event.preventDefault();

    if (!publicKey) {
      Swal.fire({
        icon: "error",
        title: intl.formatMessage({ id: "alert.transfer" }),
        text: intl.formatMessage({ id: "conectar.error" }),
      });
      return;
    }

    const amountFloat = parseFloat(amount);

    if (!amount || amountFloat < 0.01) {
      Swal.fire({
        icon: "error",
        title: intl.formatMessage({ id: "alert.transfer" }),
        text: intl.formatMessage({ id: "transfer.error" }),
      });
      return;
    }

    try {
      setLoading(true);

      const transaction = new Transaction();

      // Convertir cantidad a lamports
      const lamportsToSend = amountFloat * LAMPORTS_PER_SOL;

      // Crear la instrucción de transferencia
      const sendSolInstruction = SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: spender,
        lamports: lamportsToSend,
      });

      // Agregar instrucción a la transacción
      transaction.add(sendSolInstruction);

      // Enviar la transacción
      const signature = await sendTransaction(transaction, connection);
      console.log(`Transaction signature: ${signature}`);

      // Mostrar mensaje de éxito con la firma
      Swal.fire({
        icon: "success",
        title: intl.formatMessage({ id: "transfer.exitosa" }),
        text: intl.formatMessage({ id: "exitosa.mensaje" }, { signature }),
      });
    } catch (error) {
      console.error("Transaction failed", error);
      Swal.fire({
        icon: "error",
        title: intl.formatMessage({ id: "alert.transfer" }),
        text: intl.formatMessage({ id: "fake.transfer" }),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="transfer-container">
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder={intl.formatMessage({ id: "input.placeholder" })}
        min="0.01"
        step="0.01"
        className="amount-input"
      />
      <button
        onClick={sendSol}
        disabled={
          loading || !publicKey || balance === 0 || parseFloat(amount) * LAMPORTS_PER_SOL > balance
        }
        className="transfer-button"
      >
        {loading
          ? intl.formatMessage({ id: "transfer.boton" })
          : intl.formatMessage({ id: "transfer.transfer" }, { amount })}
      </button>
    </div>
  );
};

export default TransferButton;
